import useSWR from "swr";
import WorkflowAutomationPanelManual from "./WorkflowAutomationPanelManual";
import { getData } from "../backend";
import { WorkflowContext } from "../contexts";
import { useContext, useState } from "react";
import { SignatureRequest } from "../interfaces"
import SignatureLink from "./SignatureLink";


function WorkflowAutomationPanelSignatureLink() {
  const { workflow } = useContext(WorkflowContext);
  const { data, mutate } = useSWR<Array<SignatureRequest>>(`/workflows/${workflow.id}/signature-request-links/`, getData, { revalidateOnFocus: true });

  const [isUpdating, setIsUpdating] = useState(false);

  return (
    <div className="flex flex-col gap-4">
      {(data?.[0] && !isUpdating) &&
        <SignatureLink signatureRequest={data?.[0]} />
      }
      {isUpdating &&
        <div className="font-semibold text-gray-600 text-sm">New version is being published now<span className="loading-ellipse" /></div>
      }
      <WorkflowAutomationPanelManual buttonText="Publish Latest Version" onComplete={() => { setIsUpdating(true); mutate().then(() => setIsUpdating(false)) }} />
    </div>
  );

}

export default WorkflowAutomationPanelSignatureLink;
