import { useLayoutEffect } from "react";

type Props = {
  saveState: string;
  handleSave: () => void;
  allowEditing: boolean;
}

function WorkflowEmailSave(props: Props) {
  const { saveState, allowEditing, handleSave } = props;
  const isMac = navigator.platform.toLowerCase().indexOf("mac") >= 0;

  useLayoutEffect(() => {
    function metaEnterHandler(e: KeyboardEvent) {
      if (e.key === 'Enter' && (e.metaKey || e.ctrlKey) && handleSave)
        handleSave();
    }

    window.addEventListener('keydown', metaEnterHandler);
    return () => {
      window.removeEventListener('keydown', metaEnterHandler);
    };

  }, [handleSave]);

  return (
    <div className="relative group ml-auto">
      {(saveState === "INITIAL") &&
        <button className=" btn btn-blue w-32" disabled>
          Save
        </button>
      }
      {(saveState === "HAS_CHANGES") &&
        <button className=" btn btn-blue w-32" onClick={handleSave} disabled={!allowEditing}>
          Save
        </button>
      }
      {(saveState === "SAVING") &&
        <button className=" btn btn-blue w-32" disabled>
          Saving...
        </button>
      }
      {(saveState === "SAVED") &&
        <button className=" btn btn-green w-32">
          Saved
        </button>
      }
      <p className="text-xs font-semibold font-gilroy text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity text-center">
        Shortcut: {isMac ? "⌘" : "Ctrl "} + Enter
      </p>
    </div>
  );
}

export default WorkflowEmailSave;
