import { CalendarIcon, CheckIcon, DatabaseIcon, ExternalLinkIcon, PencilAltIcon, PencilIcon, PlusCircleIcon, TrashIcon, XIcon } from "@heroicons/react/outline";
import { useContext, useEffect, useState } from "react";
import { useModal, WorkflowContext } from "../contexts";
import { BLOCK_ICON_MAP, RECIPIENT_COLOUR_MAP } from "../utils";
import ToggleButton from "./ToggleButton";
import GooglePickerButton from "./GooglePickerButton";
import ProLabel from "./ProLabel";
import WorkflowDocumentOutputName from "./WorkflowDocumentOutptName";
import WorkflowSelectModal from "./WorkflowSelectModal";
import { Document, SignatureRequest } from "../interfaces";
import { getData, OAuthError, postData, putData } from "../backend";
import useUser from "../hooks/useUser";
import PayWallModalPro from "./PayWallModalPro";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import TransitionWorkflowPage from "./transitions/TransitionWorkflowPage";
import TransitionWorkflowPageSideBar from "./transitions/TransitionWorkflowPageSideBar";
import TransitionWorkflowPageContent from "./transitions/TransitionWorkflowPageContent";
import OAuthModal from "./OAuthModal";
import ErrorModal from "./ErrorModal";
import SignatureLink from "./SignatureLink";
import useSWR from "swr";

type Field = "signature" | "initials" | "date" | "text" | "checkbox";

function WorkflowDocumentSignatureOnly() {
  const { user, hasPermission } = useUser();
  const { openModal } = useModal();

  const history = useHistory();
  const location = useLocation();
  const { url } = useRouteMatch();

  const workflowContext = useContext(WorkflowContext)
  const { workflow, mutateWorkflow } = workflowContext;

  const { data: linkSignatureRequests } = useSWR<Array<SignatureRequest>>(workflow?.source?.sourceType === "SIGNATURE_LINK" ? `/workflows/${workflow.id}/signature-request-links/` : null, getData, { revalidateOnFocus: true, fallbackData: [] });
  const firstLinkSignatureRequest = linkSignatureRequests?.[0];

  const [textColor, bgColor] = RECIPIENT_COLOUR_MAP[1];

  const index = (() => {
    const hash = location.hash.split("#")[1];

    if (hash === "signable")
      return workflow.documents.indexOf(workflow.documents.filter(d => d.isSignable)[0]);

    return (parseInt(hash) || 1) - 1;
  })();

  const initialDocument = workflow.documents[index] ?? workflow.documents[0];
  const [document, setDocument] = useState<Document>();

  useEffect(() => {
    setDocument(initialDocument);
  }, [initialDocument]);

  async function updateDocument(field: string, value: any) {
    if (!document)
      return;

    const updatedDocument = { ...document, [field]: value };
    setDocument(updatedDocument);

    await putData(`/workflows/documents/${document!.id}/`, updatedDocument)
      .then(setDocument)
      .then(() => mutateWorkflow())
      .catch(console.error);
  }

  async function handleChangeFile(file: any) {
    try {
      await putData(`/workflows/documents/${document!.id}/file/`, { file })
    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} />)
      else
        openModal(<ErrorModal details={error} />)
    }

    await mutateWorkflow();
  }

  function toggleRemoveBranding(flag: boolean) {
    if (!user?.subscription?.featureRemoveBranding) {
      openModal(<PayWallModalPro />)
      return;
    }

    updateDocument("removeBranding", flag);
  }

  const isOpened = true;
  const [clickedField, setClickedField] = useState<Field>();

  async function handleInsertSignatureField(field: Field) {
    if (clickedField)
      return; // Prevent action while a field is already being inserted

    try {
      setClickedField(field);
      await postData(`/workflows/documents/${document!.id}/add-signature-field/`, { type: field, recipient: 1 })
    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} />);
      else
        openModal(<ErrorModal details={error} />);
    } finally {
      setClickedField(undefined)
    }
  }

  if (!document) {
    if (!location.pathname.endsWith("/document"))
      return null;

    return (
      <div className="flex flex-col items-center justify-center gap-4 h-full w-full">
        <div className="font-semibold font-gilroy text-gray-600 text-center w-96">
          This workflow contains no document templates, would you like to add some?
        </div>
        <button className="btn btn-blue" onClick={() => openModal(<WorkflowSelectModal title="Add Workflow Template" subtitle="Add a template to your workflow" showDocuments workflowContext={workflowContext} />)}>
          Add Document Template
        </button>
      </div>
    );
  }

  return (
    <TransitionWorkflowPage show={location.pathname.endsWith("/document")}>
      {hasPermission(workflow.team, "workflows.edit") &&
        <TransitionWorkflowPageSideBar>
          {!document.isSignable &&
            <div className="flex flex-col gap-2 my-auto">
              <div className="flex flex-col gap-1">
                <div className="flex items-center font-gilroy font-semibold text-gray-600">
                  <PencilAltIcon className="w-6 h-6 mr-1" /> Make document signable
                </div>
                <div className="font-semibold text-xs text-gray-400">
                  Add signable fields to this document
                </div>
              </div>
              <button className="btn btn-blue w-full" onClick={() => updateDocument("isSignable", true).then(() => mutateWorkflow())}>
                Add
              </button>
            </div>
          }

          {!!workflow.source && document.isSignable &&
            <>
              {firstLinkSignatureRequest &&
                <SignatureLink signatureRequest={firstLinkSignatureRequest} />
              }

              {document.isSignable &&
                <div className="border border-gray-400 rounded">
                  <div className={`flex flex-col gap-1 border-b px-2 pt-1 bg-opacity-10 rounded ${bgColor}`}>
                    <div className={`flex items-center w-full ${textColor} font-gilroy font-semibold text-sm`}>
                      Signature Fields
                    </div>
                  </div>

                  <div className={`flex flex-col ${document && isOpened ? "block" : "hidden"}`}>
                    <div className="flex items-center text-sm border-b px-2 py-1">
                      <PencilAltIcon className="w-4 h-4 mr-2" />
                      <div className="font-semibold whitespace-nowrap py-2">
                        Signature
                      </div>
                      <button className={`ml-auto btn btn-white px-2 py-1 w-20 ${clickedField === "signature" ? "bg-blue text-white" : "bg-white text-blue"}`} onClick={() => handleInsertSignatureField("signature")}>
                        {clickedField === "signature" ? "Inserting..." : <><PlusCircleIcon className="w-4 h-4 mr-1" /> Insert</>}
                      </button>
                    </div>
                    <div className="flex items-center text-sm border-b px-2 py-1">
                      <div className="w-4 h-4 mr-2 font-gilroy text-sm underline">
                        AB
                      </div>
                      <div className="font-semibold whitespace-nowrap py-2">
                        Initials
                      </div>
                      <button className={`ml-auto btn btn-white px-2 py-1 w-20 ${clickedField === "initials" ? "bg-blue text-white" : "bg-white text-blue"}`} onClick={() => handleInsertSignatureField("initials")}>
                        {clickedField === "initials" ? "Inserting..." : <><PlusCircleIcon className="w-4 h-4 mr-1" /> Insert</>}
                      </button>
                    </div>
                    <div className="flex items-center text-sm border-b px-2 py-1">
                      <CalendarIcon className="w-4 h-4 mr-2" />
                      <div className="font-semibold whitespace-nowrap py-2">
                        Date
                      </div>
                      <div className="text-xs ml-auto flex items-start group cursor-pointer hover:text-blue hover:underline">
                        <div className="ml-1">
                          {(workflow.owner?.timeFormat) === "HOUR_12" && "12Hr"}
                          {(workflow.owner?.timeFormat) === "HOUR_24" && "24Hr"}
                        </div>
                        <PencilIcon className="opacity-0 group-hover:opacity-100 w-3 h-3 ml-1" />
                      </div>
                      <button className={`ml-auto btn btn-white px-2 py-1 w-20 ${clickedField === "date" ? "bg-blue text-white" : "bg-white text-blue"}`} onClick={() => handleInsertSignatureField("date")}>
                        {clickedField === "date" ? "Inserting..." : <><PlusCircleIcon className="w-4 h-4 mr-1" /> Insert</>}
                      </button>
                    </div>
                    <div className="flex items-center text-sm border-b px-2 py-1">
                      <div className="w-4 h-4 mr-2 font-gilroy text-sm underline">
                        Tt
                      </div>
                      <div className="font-semibold whitespace-nowrap py-2">
                        Text
                      </div>
                      <button className={`ml-auto btn btn-white px-2 py-1 w-20 ${clickedField === "text" ? "bg-blue text-white" : "bg-white text-blue"}`} onClick={() => handleInsertSignatureField("text")}>
                        {clickedField === "text" ? "Inserting..." : <><PlusCircleIcon className="w-4 h-4 mr-1" /> Insert</>}
                      </button>
                    </div>

                    {false && // TODO: Implement requied and optional checkboxes - currently exisiting checkbox is optional
                      <div className="flex items-center text-sm border-b px-2 py-1">
                        <CheckIcon className="w-4 h-4 mr-2 border border-black border-dashed rounded" />
                        <div className="font-semibold whitespace-nowrap py-2">
                          Checkbox (Optional)
                        </div>
                        <button className={`ml-auto btn btn-white px-2 py-1 w-20 ${clickedField === "checkbox" ? "bg-blue text-white" : "bg-white text-blue"}`} onClick={() => handleInsertSignatureField("checkbox")}>
                          {clickedField === "checkbox" ? "Inserting..." : <><PlusCircleIcon className="w-4 h-4 mr-1" /> Insert</>}
                        </button>
                      </div>
                    }

                    <div className="flex items-center text-sm px-2 py-1">
                      <CheckIcon className="w-4 h-4 mr-2 border border-black rounded" />
                      <div className="font-semibold whitespace-nowrap py-2">
                        Checkbox
                      </div>
                      <button className={`ml-auto btn btn-white px-2 py-1 w-20 ${clickedField === "checkbox" ? "bg-blue text-white" : "bg-white text-blue"}`} onClick={() => handleInsertSignatureField("checkbox")}>
                        {clickedField === "checkbox" ? "Inserting..." : <><PlusCircleIcon className="w-4 h-4 mr-1" /> Insert</>}
                      </button>
                    </div>
                    <div className="flex flex-col py-2 px-2 border-t gap-2 text-xs">
                      <div className=" text-gray-600">
                        Signable fields will be inserted at the <b>end of the document</b> and can be moved to any location.
                      </div>
                      {/* TODO: Implement required fields option */}
                      {/* <div className='flex items-center gap-2'>
                        <ToggleButton value={true} onChange={() => alert("TODO: Implement option")} />
                        <div className='font-semibold text-gray-600 flex flex-col'>
                          <div>TODO: Require all fields to be completed</div>
                        </div>
                      </div> */}
                    </div>
                  </div>

                </div>
              }

              <WorkflowDocumentOutputName initialValue={document.outputName} onEnter={(value) => updateDocument("outputName", value)} />

              <div className="flex justify-start items-center font-gilroy font-semibold text-sm  w-full">
                {/* TODO: Open paywall or toggle setting */}
                <ToggleButton value={!!user?.subscription?.featureRemoveBranding && document.removeBranding} disabled={!(document.isSignable || document.createPdfCopy)} onChange={toggleRemoveBranding} />
                <div className={`font-semibold text-sm ml-2 mr-1 ${(document.isSignable || document.createPdfCopy) ? "text-gray-600" : "text-gray-400"}`}>
                  Remove Portant PDF branding
                </div>
                <ProLabel />
              </div>

              {(workflow.documents.length === 1 && linkSignatureRequests?.length === 0) &&
                <>
                  <hr className="mt-auto" />
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-col gap-1">
                      <div className="flex items-center font-gilroy font-semibold text-gray-600">
                        <DatabaseIcon className="w-6 h-6 mr-1" /> Add a Source <div className="text-xs text-gray-400 ml-2 mt-1">Optional</div>
                      </div>
                      <div className="font-semibold text-xs text-gray-400">
                        Automatically create documents by connecting a source
                      </div>
                    </div>
                    <button className="btn btn-white w-40 text-xs" onClick={() => openModal(<WorkflowSelectModal title="Add source" subtitle="Add an source for automated workflows" showSources workflowContext={workflowContext} />)}>
                      Add
                    </button>
                  </div>
                </>
              }
            </>}
        </TransitionWorkflowPageSideBar>
      }

      <TransitionWorkflowPageContent>
        <div className="flex w-full items-center mb-2">
          <div className="flex justify-center items-center my-auto mr-2 rounded w-10 h-10">
            <img className="h-10 w-10 rounded-md" src={BLOCK_ICON_MAP[document.documentType]} alt="Google File Icon" />
          </div>
          <div className="flex flex-col h-full justify-center">
            <div className="flex text-sm font-semibold font-gilroy cursor-pointer hover:text-blue hover:underline" onClick={() => window.open(document.file?.url, "_blank")}>
              <div className="truncate max-w-[400px]">
                {document.file?.name}
              </div>
              {document.file?.url &&
                <ExternalLinkIcon className="h-4 w-4 ml-1 mb-1 group-hover:text-blue" />
              }
            </div>
            {hasPermission(workflow.team, "workflows.edit") &&
              <div className="flex gap-2 w-full">
                <GooglePickerButton className="flex items-center text-gray-500 text-sm font-gilroy font-medium hover:underline hover:text-gray-600" blockType={document.documentType} onPicked={handleChangeFile}>
                  <PencilIcon className="h-4 mr-1" /><>Change selected file</>
                </GooglePickerButton>
                {false && workflow.documents.length > 1 &&
                  <div className="flex items-center text-gray-500 text-sm font-gilroy font-medium cursor-pointer hover:underline hover:text-red" onClick={() => alert("Not implemented")}>
                    <TrashIcon className="h-4 mr-1" /><>Remove block</>
                  </div>
                }
              </div>
            }
          </div>
          <XIcon className="w-6 h-6 ml-auto text-gray-400 hover:text-black cursor-pointer" onClick={() => history.push(url)} />
        </div>
        {document.file
          ? <iframe title="Google Editor" className="w-full h-full border border-gray-300 rounded overflow-hidden" src={`${document.file!.url}?widget&rm=embedded`} />
          : <div className="m-auto">No file selected</div>
        }
      </TransitionWorkflowPageContent>
    </TransitionWorkflowPage>
  );

}

export default WorkflowDocumentSignatureOnly;
