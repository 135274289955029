import { Transition } from "@headlessui/react";
import { DocumentTextIcon, XIcon } from "@heroicons/react/outline";
import { useContext } from "react";
import { useModal, WorkflowContext } from "../contexts";
import WorkflowSelectModal from "./WorkflowSelectModal";
import { BLOCK_ICON_MAP } from "../utils";
import { useHistory, useRouteMatch } from "react-router-dom";
import useUser from "../hooks/useUser";
import useSWR from "swr";
import { SignatureRequest } from "../interfaces";
import { getData } from "../backend";
import SignatureLink from "./SignatureLink";


function WorkflowSourceSignatureLink() {
  const { openModal } = useModal();

  const { hasPermission } = useUser();

  const workflowContext = useContext(WorkflowContext);
  const { workflow, showAutomationPanel } = workflowContext;

  const history = useHistory();
  const { url } = useRouteMatch();

  const source = workflow.source;

  const showSourceOptions = workflow.documents.length > 0 || workflow.emails.length > 0;

  const { data: linkSignatureRequests } = useSWR<Array<SignatureRequest>>(workflow?.source?.sourceType === "SIGNATURE_LINK" ? `/workflows/${workflow.id}/signature-request-links/` : null, getData, { revalidateOnFocus: true, fallbackData: [] });

  if (!source)
    return null;

  return (
    <>
      {hasPermission(workflow.team, "workflows.edit") &&
        <Transition.Child className={`flex flex-col bg-white h-full w-[360px] flex-shrink-0 p-4 pb-24 gap-4 overflow-auto ${showAutomationPanel && "blur-sm"}`}
          enter="transition ease-in-out duration-150 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-150 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >

          {showSourceOptions && <>
            <div className="flex flex-col text-gray-600 font-semibold">
              Signature links will remain signable until they are unpublished.
            </div>
          </>}

          {!showSourceOptions && <>
            <div className="flex flex-col gap-4 justify-center h-full">
              <div className="flex items-center">
                <DocumentTextIcon className="w-6 h-6 mr-2" />
                <div className="font-gilroy font-semibold text-lg">
                  Connect a Template
                </div>
              </div>
              <div className="flex flex-col gap-2 font-semibold text-gray-600">
                Create documents and presentations from a template
                <div className="flex items-center gap-2">
                  <div className="h-px bg-gray-600 w-full" />
                  <div className="flex-shrink-0">or</div>
                  <div className="h-px bg-gray-600 w-full" />
                </div>
                Create and send emails from a templated&nbsp;draft
              </div>
              <button className="btn btn-blue" onClick={() => openModal(<WorkflowSelectModal title="Select Temaplate" subtitle="Create or select a template to merge your data with" showDocuments workflowContext={workflowContext} />)}>
                Connect
              </button>
            </div>
          </>}
        </Transition.Child>
      }

      <Transition.Child className="flex flex-col bg-white w-full m-4 p-4 pt-2 shadow rounded"
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex w-full items-center mb-2">
          <div className="flex justify-center items-center my-auto mr-2 rounded w-10 h-10">
            <img className="h-10 w-10 rounded-md" src={BLOCK_ICON_MAP["SIGNATURE_LINK"]} alt="Signature Icon" />
          </div>
          <div className="flex flex-col h-full justify-center">
            <div className="flex text-sm font-semibold font-gilroy">
              <div className="truncate max-w-[400px]">
                Signature Links
              </div>
            </div>
          </div>
          <XIcon className="w-6 h-6 ml-auto text-gray-400 hover:text-black cursor-pointer" onClick={() => history.push(url)} />
        </div>
        <div className="flex flex-col gap-8 overflow-y-scroll h-full items-center w-full">
          {linkSignatureRequests?.map((signatureRequest, index) => (
            <SignatureLink key={index} signatureRequest={signatureRequest} className="w-[800px]" disableViewAll />
          ))}
        </div>
      </Transition.Child>
    </>
  );

}

export default WorkflowSourceSignatureLink;
