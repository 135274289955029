import { useContext } from 'react';
import { WorkflowContext } from '../contexts';
import { useModal } from '../contexts';
import { Transition } from '@headlessui/react';
import WorkflowSelectModal from './WorkflowSelectModal';
import WorkflowOverviewBlockDocument from './WorkflowOverviewBlockDocument';
import WorkflowOverviewBlockPlaceholder from './WorkflowOverviewBlockPlaceholder';
import React from 'react';

interface Props {
  contentOffset: number;
  show: boolean;
}

function WorkflowOverviewSignatureOnly(props: Props) {
  const { openModal } = useModal();
  const { contentOffset } = props;

  const workflowContext = useContext(WorkflowContext);
  const { workflow } = workflowContext;

  function handleAddBlock(modalProps: any) {
    openModal(<WorkflowSelectModal title="Add Workflow Block" subtitle="Add a new block to your workflow" {...modalProps} workflowContext={workflowContext} />);
  }

  let documents: JSX.Element | null = null;
  if (workflow.documents.length > 0)
    documents = (
      <div className='flex flex-col items-center'>
        {workflow.documents.map((d, index) => (
          <React.Fragment key={d.id}>
            <WorkflowOverviewBlockDocument key={d.id} block={d} index={index} disableDelete />
          </React.Fragment>
        ))}
      </div>
    )
  else if (workflow.documents.length === 0 && workflow.emails.length === 0)
    documents = (
      <div className="flex flex-col items-center">
        <WorkflowOverviewBlockPlaceholder text="Add template" onClick={() => handleAddBlock({ showDocuments: true })} />
      </div>
    );

  return (
    <Transition show={props.show} className="flex flex-col items-center w-full p-8 pb-20 overflow-auto" style={{ height: `calc(100vh - 56px - ${contentOffset}px)` }}>
      {documents}
    </Transition>
  );
}

export default WorkflowOverviewSignatureOnly;
