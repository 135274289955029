import { useContext, useState } from "react";
import { ClipboardCopyIcon, ExternalLinkIcon, LinkIcon } from "@heroicons/react/outline";
import { SignatureRequest } from "../interfaces";
import CopyToClipboard from "react-copy-to-clipboard";
import moment from "moment";
import { getData, postData } from "../backend";
import { WorkflowContext } from "../contexts";
import { Link } from "react-router-dom";
import useSWR from "swr";

interface Props {
  signatureRequest: SignatureRequest;
  className?: string;
  disableViewAll?: boolean;
}

function SignatureLink(props: Props) {

  const { signatureRequest } = props;
  const { publicTokenUrl } = signatureRequest;

  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [isUnpublishing, setIsUnpublishing] = useState(false);

  const workflowContext = useContext(WorkflowContext);
  const { workflow } = workflowContext;

  const { mutate: mutateLinkSignatureRequests } = useSWR<Array<SignatureRequest>>(`/workflows/${workflow.id}/signature-request-links/`, getData, { revalidateOnFocus: true });

  function handleCopyLink() {
    setIsLinkCopied(true);
    setTimeout(() => setIsLinkCopied(false), 1000);
  }

  function handleUnpublish() {
    setIsUnpublishing(true);
    postData(`/signatures/unpublish/${signatureRequest.id}/`)
      .then(() => mutateLinkSignatureRequests((data) => {
        if (!data)
          return data;

        return data.map(sr => {
          if (sr.id === signatureRequest.id)
            return { ...sr, status: "LINK_UNPUBLISHED" };

          return sr;
        });
      }))
      .finally(() => setIsUnpublishing(false));
  }

  return (
    <div className={`flex flex-col gap-1 ${props.className}`}>
      <div className="flex items-center font-gilroy font-semibold text-sm text-gray-600 whitespace-nowrap">
        <LinkIcon className="w-4 h-4 mr-1" />
        <div className="mr-2">Signature Link</div>
        <div className="ml-auto text-xs font-semibold whitespace-nowrap truncate">Published {moment(signatureRequest.createdAt).fromNow()}</div>
      </div>
      <div className="flex items-center gap-2">
        <input type="text" className="w-full text-sm bg-gray-100 text-gray-600 border rounded-sm outline-none h-8 px-2" value={publicTokenUrl} onClick={e => e.currentTarget.select()} readOnly />
        <CopyToClipboard text={publicTokenUrl} onCopy={handleCopyLink}>
          <button className="relative flex items-center justify-center border bg-white h-8 w-8 flex-shrink-0 rounded-sm group shadow-sm">
            <ClipboardCopyIcon className="h-4 w-4 group-hover:text-blue" />
            <div className="hidden group-hover:block absolute bg-black text-white whitespace-nowrap text-xs top-9 right-4 py-1 px-2 rounded-md rounded-tr-none">
              {isLinkCopied ? "Copied" : "Copy to clipboard"}
            </div>
          </button>
        </CopyToClipboard>
        <a className="relative flex items-center justify-center border bg-white h-8 w-8 flex-shrink-0 rounded-sm group shadow-sm" href={publicTokenUrl} target="_blank" rel="noreferrer">
          <ExternalLinkIcon className="h-4 w-4 group-hover:text-blue" />
          <div className="hidden group-hover:block absolute bg-black text-white whitespace-nowrap text-xs top-9 right-4 py-1 px-2 rounded-md rounded-tr-none">
            Open in new tab
          </div>
        </a>
      </div>
      <div className="flex items-center justify-between text-xs font-semibold text-gray-400">
        {signatureRequest.status === "LINK_UNPUBLISHED"
          ? <div className="text-red">This link has been unpublished</div>
          : <button className="text-gray-400 hover:text-red hover:underline cursor-pointer disabled:text-yellow" onClick={handleUnpublish} disabled={isUnpublishing}>{isUnpublishing ? "Unpublishing..." : "Unpublish this link"}</button>
        }
        {!props.disableViewAll &&
          <Link className="hover:underline cursor-pointer" to={`/w/${workflow.id}/source`}>View all</Link>
        }
      </div>
    </div>
  );
}

export default SignatureLink;
