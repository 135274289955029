import { FolderOpenIcon, LightningBoltIcon } from "@heroicons/react/outline";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { Workflow } from "../interfaces";

interface Props {
  className?: string;
  workflow: Workflow;
}

function WorkflowDetailNavigation(props: Props) {
  const { workflow, className } = props;
  const location = useLocation();
  const { url } = useRouteMatch();

  const lastViewedOutputs = (() => {
    if (!workflow)
      return 0;

    const key = "lastViewedOuputsMap";
    const lastViewedOuputsMapString = localStorage.getItem(key) ?? "{}";

    const lastViewedOuputsMap: Record<string, number> = JSON.parse(lastViewedOuputsMapString);
    return lastViewedOuputsMap[workflow.id] ?? 0;
  })();

  return (
    <>
      <div className={`hidden lg:flex relative w-40 rounded-full bg-gray-100 flex-row justify-center items-center ${className} p-1`}>
        <div className={`absolute inset-0 bg-blue-50 rounded-full w-20 transition-all`} style={{ left: location.pathname === (url + "/outputs") ? "80px" : "0px" }} />

        <Link
          to={url}
          className={`group flex flex-col items-center justify-center relative w-20 rounded-full cursor-pointer ${location.pathname !== (url + "/outputs") ? "text-gray-600" : "text-gray-400 hover:underline"}`}
        >
          <LightningBoltIcon className={`mx-auto h-5 w-5`} />
          <p className={`text-xs text-center font-gilroy font-semibold`}>
            Workflow
          </p>
        </Link>

        <Link
          className={`group flex flex-col items-center justify-center relative w-20 rounded-full cursor-pointer ${location.pathname === (url + "/outputs") ? "text-gray-600" : "text-gray-400 hover:underline"}`}
          to={url + "/outputs"}
        >
          <FolderOpenIcon className={`mx-auto h-5 w-5`} />
          <p className={`text-xs text-center font-gilroy font-semibold`}>
            Outputs
          </p>
          {(workflow.latestAutomation && workflow.latestAutomation.itemsCompleted > 0 && (lastViewedOutputs < new Date(workflow.latestAutomation.updatedAt).getTime())) &&
            <div className="absolute bg-green border-2 border-white drop-shadow-sm w-4 h-4 -top-1 right-5 rounded-full" />
          }
        </Link>
      </div>

      <div className={`flex lg:hidden relative w-20 rounded-full bg-gray-100 flex-row justify-center items-center ${className} p-1`}>
        <div className={`absolute inset-0 bg-blue-50 rounded-full w-10 transition-all`} style={{ left: location.pathname === (url + "/outputs") ? "40px" : "0px" }} />

        <Link
          to={url}
          className={`group flex flex-col items-center justify-center relative w-10 rounded-full cursor-pointer ${location.pathname !== (url + "/outputs") ? "text-gray-600" : "text-gray-400 hover:underline"}`}
        >
          <LightningBoltIcon className={`mx-auto w-6 h-6`} />
        </Link>

        <Link
          className={`group flex flex-col items-center justify-center relative w-10 rounded-full cursor-pointer ${location.pathname === (url + "/outputs") ? "text-gray-600" : "text-gray-400 hover:underline"}`}
          to={url + "/outputs"}
        >
          <FolderOpenIcon className={`mx-auto w-6 h-6`} />
          {(workflow.latestAutomation && workflow.latestAutomation.itemsCompleted > 0 && (lastViewedOutputs < new Date(workflow.latestAutomation.updatedAt).getTime())) &&
            <div className="absolute bg-green border-2 border-white drop-shadow-sm w-4 h-4 top-1 right-4 rounded-full" />
          }
        </Link>
      </div>
    </>
  );
}

export default WorkflowDetailNavigation;