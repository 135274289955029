import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon, ExclamationIcon, PencilIcon, StarIcon, XIcon } from "@heroicons/react/outline";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { OAuthError, deleteData, patchData, postData, putData } from "../backend";
import { WorkflowContext, useModal } from "../contexts";
import { BLOCK_ICON_MAP, capitalize, luminance } from "../utils";
import useUser from "../hooks/useUser";
import { SignatureConfig, Email, AttachmentType, Attachment, TempAttachment } from "../interfaces";
import WorkflowBlockModalSave from './WorkflowModalSave';
import OAuthModal from './OAuthModal';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import moment from 'moment-timezone';
import OAuthModalMicrosoft from './OAuthModalMicrosoft';
import WorkflowSelectModal from './WorkflowSelectModal';
import TransitionWorkflowPage from './transitions/TransitionWorkflowPage';
import WorkflowEmailFrom from './WorkflowEmailFrom';
import WorkflowEmailSubject from './WorkflowEmailSubject';
import WorkflowEmailToTags from './WorkflowEmailToTags';
import WorkflowSignatureRequestSidebar from './WorkflowSignatureRequestSidebar';
import WorkflowEmailSave from './WorkflowEmailSave';
import WorkflowEmailAttachments from './WorkflowEmailAttachments';
import useRichTextfield from '../hooks/useRichTextfield';
import WorkflowEmailToolbar from './WorkflowEmailToolbar';
import useDebounce from '../hooks/useDebounce';
import EmailModalAttachment from './EmailModalAttachment';

type SaveState = "INITIAL" | "HAS_CHANGES" | "SAVING" | "SAVED";

function WorkflowSignatureRequest() {
  const { user, hasPermission } = useUser();

  const history = useHistory();
  const { pathname } = useLocation();

  const { url } = useRouteMatch();

  const workflowContext = useContext(WorkflowContext);
  const { workflow, mutateWorkflow } = workflowContext;

  const allowEditing = hasPermission(workflow.team, "workflows.edit");

  const [signatureConfig, setSignatureConfig] = useState(workflow.signatureConfig);

  const [attachments, setAttachments] = useState<Attachment[]>(signatureConfig?.attachments ?? []);
  const [attachmentsToPost, setAttachmentsToPost] = useState<TempAttachment[]>([]);
  const [attachmentsToModify, setAttachmentsToModify] = useState<{ id: string, sharingMode: string }[]>([]);
  const [attachmentIdsToRemove, setAttachmentIdsToRemove] = useState<string[]>([]);

  // TODO: Eventually replace every field in "signatureConfig" for this new variable
  // The issue with signatureConfig is that it updates the back instantaneously (with a post) without waiting for the user to *save* the changes
  // The current fields are a bit complex so I'd rather replace them bit by bit to prevent breaking everything
  const [emailConfig, setEmailConfig] = useState<Partial<Email>>({
    id: workflow.signatureConfig?.id,
    updatedAt: workflow.signatureConfig?.updatedAt,
    createdAt: workflow.signatureConfig?.createdAt,
    enableTracking: workflow.signatureConfig?.enableTracking,
    attachments: workflow.signatureConfig?.attachments ?? [],

    emailContent: workflow.signatureConfig?.emailContent ?? '',
    emailButtonText: workflow.signatureConfig?.emailButtonText ?? '',
    emailFromAlias: workflow.signatureConfig?.emailFromAlias ?? '',
    emailSubject: workflow.signatureConfig?.emailSubject ?? '',
    emailType: workflow.signatureConfig?.emailType ?? 'GMAIL',
  })

  const richEditorRef = useRef<HTMLDivElement | null>(null);

  const formattingMenuContainer = useRef<HTMLDivElement | null>(null);

  const [saveState, setSaveState] = useState<SaveState>("INITIAL");

  const [hasCheckedScopes, setHasCheckedScopes] = useState(false);

  const customFormattingEnabled = user?.subscription?.featureSignatures && signatureConfig?.customFormatting;

  const { RichTextfield, applyFormat, addLink, addImage, restoreLastSelection, insertCustom } = useRichTextfield({ content: emailConfig.emailContent ?? '', editorRef: richEditorRef, setContent: (value: string) => updateEmailConfig('emailContent', value) });
  const { openModal, closeModal } = useModal();

  useEffect(() => {
    setSignatureConfig(workflow.signatureConfig);
    setEmailConfig(prev => ({ ...prev, emailButtonText: workflow.signatureConfig?.emailButtonText }));
  }, [workflow.signatureConfig]);

  useEffect(() => {
    if (hasCheckedScopes || !pathname.endsWith("/signature-request"))
      return;

    postData("/auth/scopes/", { scopes: ["https://www.googleapis.com/auth/gmail.send"] })
      .catch(error => {
        if (error instanceof OAuthError)
          openModal(<OAuthModal title='Portant requires your permission to send emails' oauthUrl={error.oauthUrl} />);
      })
      .finally(() => setHasCheckedScopes(true));
  }, [hasCheckedScopes, openModal, pathname]);

  function handleUpdateEmail({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (!(target.name in emailConfig)) return;

    setEmailConfig(prev => ({ ...prev, [target.name]: target.value }));
    setSaveState("HAS_CHANGES");
  }

  function updateEmailConfig(field: string, value: any) {
    setSaveState("HAS_CHANGES");
    setEmailConfig(prev => ({ ...prev, [field]: value }));
  }

  function updateCustomFormating(customFormatting: boolean) {
    if (!customFormatting)
      updateEmailConfig("emailContent", "Please sign the following the documents.");
    updateSignatureConfig({ customFormatting });
  }

  async function updateSignatureConfig(updates: any) {
    if (!signatureConfig)
      return;

    if (updates.emailType === "OUTLOOK") {
      postData("/microsoft/access-token/")
        .catch(error => {
          updateSignatureConfig({ emailType: "GMAIL" })
          if (error instanceof OAuthError) {
            openModal(<OAuthModalMicrosoft oauthUrl={error.oauthUrl} onClose={() => { updateSignatureConfig({ emailType: "OUTLOOK" }) }} />);
          }
        })
    }

    const updatedSignatureConfig = { ...signatureConfig!, ...updates };
    setSignatureConfig(updatedSignatureConfig);

    await putData(`/signatures/signature-configs/${updatedSignatureConfig.id}/`, updatedSignatureConfig)
      .catch(console.error);

    mutateWorkflow();
  }

  async function handleSave() {
    let updates: Record<string, string | boolean> = {
      emailSubject: emailConfig.emailSubject ?? "",
    }

    if (customFormattingEnabled) {
      updates["emailContent"] = emailConfig.emailContent ?? "";
    } else {
      updates["emailContent"] = emailConfig.emailContent ?? "";
    }

    if (emailConfig.emailFromAlias !== workflow.signatureConfig?.emailFromAlias) {
      updates.emailFromAlias = emailConfig.emailFromAlias as string;
    }

    if (emailConfig.emailButtonText !== undefined && emailConfig.emailButtonText !== signatureConfig?.emailButtonText)
      updates["emailButtonText"] = emailConfig.emailButtonText;

    let updatedSignatureConfig: SignatureConfig = {
      ...signatureConfig!, ...updates
    }

    setSaveState("SAVING");

    let postedAttachmentIds: Record<string, string> = {};

    attachmentsToPost.forEach(async (attachment: TempAttachment) => {
      const posted = await postData(`/signatures/signature-configs/${signatureConfig?.id}/attachments/`, attachment)
      postedAttachmentIds[attachment.id] = posted.id;
    });

    attachmentIdsToRemove.forEach(async attachmentId => {
      await deleteData(`/signatures/signature-config-attachments/${attachmentId}/`)
    });

    attachmentsToModify.forEach(async attachment => {
      const idToModify = postedAttachmentIds[attachment.id] ?? attachment.id;
      await patchData(`/signatures/signature-config-attachments/${idToModify}/`, { sharingMode: attachment.sharingMode });
    });

    setAttachmentIdsToRemove([]);
    setAttachmentsToPost([]);
    setAttachmentsToModify([]);

    await putData(`/signatures/signature-configs/${signatureConfig!.id}/`, updatedSignatureConfig);
    await mutateWorkflow();

    setSaveState("SAVED");
  }

  function handleAttemptClose() {
    if (saveState === "HAS_CHANGES")
      return openModal(<WorkflowBlockModalSave onSave={() => handleSave().then(() => history.push(url))} onDontSave={() => history.push(url)} />);

    history.push(url);
  }

  async function removeAttachment(attachmentId: string) {
    closeModal();
    setSaveState("HAS_CHANGES");
    if (!attachmentsToPost.some(a => (a.id === attachmentId)))
      setAttachmentIdsToRemove(prev => [...prev, attachmentId]);

    setAttachments(prev => prev.filter(a => a?.id !== attachmentId));
    setAttachmentsToPost(prev => prev.filter(a => a.id !== attachmentId));
    setAttachmentsToModify(prev => prev.filter(a => a.id !== attachmentId));
  }

  function addAttachment(attachmentType: AttachmentType, payload: any) {
    closeModal();

    const attachmentId = payload.file?.id ?? "temp-" + Math.random().toString(36).substr(2, 9);

    const attachment = { attachmentType, ...payload, id: attachmentId }

    setSaveState("HAS_CHANGES");
    setAttachmentsToPost(prev => [...prev, attachment]);

    setAttachments(prev => [...prev, {
      id: attachmentId,
      attachmentName: attachment.file?.name,
      attachmentMimeType: attachment.file?.mimeType,
      driveFile: attachment.file ?? null,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      attachmentType,
      workflowDocument: attachment?.workflowDocument ?? null,
      sharingMode: 'VIEWER',
    }]);
  }

  function toggleAttachmentMenu() {
    openModal(<EmailModalAttachment workflow={workflow} attachments={emailConfig.attachments!} addAttachment={addAttachment} removeAttachment={removeAttachment} showMicrosoft postSigningStage />)
  }

  function updateAttachment(attachmentId: string, sharingMode: string) {
    setSaveState("HAS_CHANGES");
    setAttachments(prev => prev.map(a => a.id === attachmentId ? { ...a, sharingMode } : a));
    setAttachmentsToModify(prev => {
      const existing = prev.find(a => a.id === attachmentId);
      if (existing) {
        return prev.map(a => a.id === attachmentId ? { ...a, sharingMode } : a);
      } else {
        return [...prev, { id: attachmentId, sharingMode }];
      }
    });
  }

  const debouncedContents = useDebounce(emailConfig.emailContent, 500);

  const hasCTA = useMemo(() => {
    if (!customFormattingEnabled)
      return true;

    const contentAsHtml = document.createElement("div");
    contentAsHtml.innerHTML = debouncedContents;

    const cta = contentAsHtml.querySelectorAll("#cta-button")?.[0];
    if ((cta && cta.tagName === 'A'))
      return true;
    return false;

  }, [debouncedContents, customFormattingEnabled]);

  if (!signatureConfig) {
    if (!pathname.endsWith("/signature-request"))
      return null;

    return (
      <div className="flex flex-col items-center justify-center gap-4 h-full w-full">
        <div className="font-semibold font-gilroy text-gray-600 text-center w-96">
          This workflow does not contain a signature request, would you like to add one?
        </div>
        <button className="btn btn-blue" onClick={() => openModal(<WorkflowSelectModal title="Add Workflow Signature Request" subtitle="Add a signature request to your workflow" showSignatureRequest workflowContext={workflowContext} />)}>
          Add Signature Request
        </button>
      </div>
    );
  }

  return (
    <>
      <TransitionWorkflowPage show={pathname.endsWith("/signature-request")}>

        {allowEditing && <WorkflowSignatureRequestSidebar setSignatureConfig={setSignatureConfig} signatureConfig={signatureConfig} updateSignatureConfig={updateSignatureConfig} />}

        <Transition.Child className="flex flex-col bg-white w-[800px] m-auto border border-gray-200 shadow rounded overflow-hidden"
          enter="transition-opacity duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className='bg-gray-100 flex items-center gap-2 px-2 py-1 border-b border-gray-200'>
            <img className="h-4 w-4" src={BLOCK_ICON_MAP[signatureConfig.emailType]} alt="Email Icon" />
            <div className='font-gilroy font-semibold text-gray-600 text-sm flex items-center'>
              Request Signatures via&nbsp;
              {hasPermission(workflow.team, "workflows.edit") &&
                <Popover className="relative">
                  <Popover.Button className='flex items-center underline'>
                    {capitalize(signatureConfig.emailType)} <ChevronDownIcon className='w-4 h-4' />
                  </Popover.Button>
                  <Popover.Panel className="absolute w-28 left-0 top-6 flex flex-col bg-white text-gray-600 drop-shadow-lg rounded text-sm z-40">
                    <Popover.Button className='font-gilroy font-semibold p-2 hover:bg-gray-50 cursor-pointer whitespace-nowrap text-left flex items-center gap-1' onClick={() => updateSignatureConfig({ emailType: "GMAIL" })}>
                      <img className="w-4 flex-shrink-0" src={BLOCK_ICON_MAP["GMAIL"]} alt="Gmail Icon" /> Gmail
                    </Popover.Button>
                    <Popover.Button className='font-gilroy font-semibold p-2 hover:bg-gray-50 cursor-pointer whitespace-nowrap text-left flex items-center gap-1' onClick={() => updateSignatureConfig({ emailType: "OUTLOOK" })}>
                      <img className="w-4 flex-shrink-0" src={BLOCK_ICON_MAP["OUTLOOK"]} alt="Outlook Icon" /> Outlook
                    </Popover.Button>
                  </Popover.Panel>
                </Popover>
              }
              {!hasPermission(workflow.team, "workflows.edit") && <>{capitalize(signatureConfig.emailType)}</>}
            </div>
            <XIcon className="w-5 h-5 ml-auto text-gray-600 hover:text-red cursor-pointer" onClick={handleAttemptClose} />
          </div>

          <div className="flex flex-col h-full p-2 flex-shrink-0">
            <WorkflowEmailFrom emailConfig={emailConfig as Email} onChange={updateEmailConfig} workflow={workflow} />
            <WorkflowEmailToTags signatureConfig={signatureConfig} />
            <hr className="border-gray-400" />
            <WorkflowEmailSubject email={emailConfig as Email} onChange={updateEmailConfig} />

            <hr className="border-gray-400 pb-2" />

            <div className="flex flex-col h-[400px]">
              {!customFormattingEnabled &&
                <div className='flex flex-col bg-gray-100 h-full w-full p-5 overflow-y-auto'>
                  <div className='bg-white w-full h-min flex flex-col gap-2 p-5 rounded flex-shrink-0'>
                    <div className='font-gilroy text-lg font-semibold'>
                      Signature Request
                    </div>
                    <div className=''>
                      {user!.fullName} has requested you sign the following documents.
                    </div>
                    <div className={allowEditing ? "border border-dashed rounded -m-1 p-1 border-gray-400 relative" : ""}>
                      <textarea
                        value={emailConfig.emailContent}
                        className='w-full min-h-[80px] resize-none focus-visible:outline-none'
                        name="emailContent"
                        onChange={handleUpdateEmail}
                      />
                      {allowEditing && <PencilIcon className='w-5 h-5 absolute right-1 bottom-1 text-gray-400' />}
                    </div>
                    <div className='relative btn p-0 text-sm my-2 rounded blue bg-blue text-white group whitespace-nowrap cursor-text w-min' style={{ backgroundColor: workflow.team?.brandingColour, color: luminance(workflow.team?.brandingColour ?? "black") >= 0.5 ? "black" : "white" }}>
                      <input
                        className='px-[20px] py-[10px] min-w-[200px] outline-none text-center rounded'
                        value={emailConfig.emailButtonText}
                        name='emailButtonText'
                        onChange={handleUpdateEmail}
                        disabled={!allowEditing}
                        style={{ backgroundColor: user?.activeTeam?.brandingColour ?? '#1875FB', color: luminance(user?.activeTeam?.brandingColour ?? "black") >= 0.5 ? "black" : "white" }}
                      />
                      <div className='invisible group-hover:visible absolute left-4 top-10 tooltip rounded-tl-none'>
                        This button will take the recipient of the email to the signable document
                      </div>
                    </div>
                    <div className='h-px bg-gray-400' />
                    <div className='text-gray-400 text-sm font-semibold'>
                      ⚠️ Warning: To prevent others from accessing your document, please do not forward this email.
                    </div>
                  </div>
                  <div className='text-xs mt-2'>
                    ©{moment().year()} <a className='text-blue underline' href="https://www.portant.co" target='blanke'>Portant</a>
                  </div>
                </div>
              }

              {customFormattingEnabled &&
                <div className="relative w-full h-full overflow-y-auto p-5 bg-gray-100">
                  <div className='bg-white p-5 rounded h-auto min-h-full flex flex-col cursor-text'>
                    {RichTextfield}
                  </div>
                </div>
              }

              <WorkflowEmailAttachments
                addingAttachment={false}
                attachments={attachments}
                removeAttachment={removeAttachment}
                updateAttachment={updateAttachment}
              />

              {allowEditing &&
                <div className="flex flex-col flex-shrink-0">
                  <div ref={formattingMenuContainer} />
                  <div className="mt-1 flex items-center z-10 flex-shrink-0 w-full">
                    <WorkflowEmailToolbar
                      showInsertCTA={!hasCTA}
                      insertCustom={insertCustom}
                      signatureRequestProps={{ customFormatting: !!customFormattingEnabled, updateCustomFormating: updateCustomFormating, }}
                      applyFormat={applyFormat}
                      addLink={addLink}
                      addImage={addImage}
                      restoreLastSelection={restoreLastSelection}
                      toggleAttachmentMenu={toggleAttachmentMenu}
                      showFormatMenu={!!customFormattingEnabled}
                    />
                    <WorkflowEmailSave allowEditing={allowEditing} handleSave={handleSave} saveState={saveState} />
                  </div>
                </div>
              }
            </div>
            <div className={`flex items-center gap-1 text-yellow font-semibold text-sm bg-yellow/20 border-yellow rounded transition-opacity flex-shrink ${!hasCTA ? 'opacity-100 h-auto p-2 border-2' : 'opacity-0 h-0 p-0'}`}>
              <ExclamationIcon className='w-5 h-5 flex-shrink-0' /> Please insert the Call To Action button somewhere in the e-mail using the "<StarIcon className='h-4 w-4' />" icon
            </div>
          </div>

        </Transition.Child>
      </TransitionWorkflowPage>
    </>
  );

}

export default WorkflowSignatureRequest;
